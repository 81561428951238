import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Fontawesome / SVG Core API
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

// Import Base / Solid Icons
import {
  faAddressCard,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faAngleDown,
  faAt,
  faAward,
  faBook,
  faBookmark,
  faBox,
  faBoxes,
  faBoxOpen,
  faBrain,
  faBriefcase,
  faBroom,
  faBug,
  faChartBar,
  faChartLine,
  faChartPie,
  faChess,
  faChevronUp,
  faChevronRight,
  faChevronDown,
  faChevronLeft,
  faCircle,
  faClock,
  faCloudDownloadAlt,
  faCode,
  faCodeBranch,
  faCog,
  faCogs,
  faComment,
  faCommentAlt,
  faCommentDots,
  faCookie,
  faCookieBite,
  faCrown,
  faCubes,
  faDatabase,
  faDesktop,
  faDiceD20,
  faDna,
  faDownload,
  faDumpster,
  faEnvelope,
  faEllipsisV,
  faExclamationTriangle,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileCode,
  faFileDownload,
  faFileExcel,
  faFileExport,
  faFileImage,
  faFileImport,
  faFilePdf,
  faFileWord,
  faFingerprint,
  faFireAlt,
  faFlask,
  faGraduationCap,
  faHome,
  faIdBadge,
  faIdCard,
  faInfo,
  faInfoCircle,
  faLaptopCode,
  faLayerGroup,
  faLightbulb,
  faLink,
  faMapMarked,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMapPin,
  faMeteor,
  faMicrochip,
  faParachuteBox,
  faPencilRuler,
  faPlus,
  faPlusCircle,
  faPoo,
  faQuoteLeft,
  faQuoteRight,
  faRobot,
  faServer,
  faShareAltSquare,
  faSquare,
  faSquareFull,
  faSwatchbook,
  faSyncAlt,
  faTachometerAlt,
  faTasks,
  faTerminal,
  faToolbox,
  faTools,
  faTrafficLight,
  faUser,
  faUserAstronaut

} from '@fortawesome/free-solid-svg-icons';

// Import Brand Icons
import {
  faAngular,
  faApple,
  faAws,
  faChrome,
  faCss3,
  faCss3Alt,
  faDocker,
  faEmber,
  faExpeditedssl,
  faFacebook,
  faFacebookSquare,
  faFirefox,
  faGithubSquare,
  faGithubAlt,
  faGitSquare,
  faGooglePlus,
  faGooglePlusSquare,
  faGrunt,
  faGulp,
  faHtml5,
  faInstagram,
  faJsSquare,
  faLinkedin,
  faLinkedinIn,
  faNode,
  faNodeJs,
  faNpm,
  faReact,
  faSass,
  faStackExchange,
  faStackOverflow,
  faTwitter,
  faTwitterSquare,
  faVuejs,
  faWindows

} from '@fortawesome/free-brands-svg-icons';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  declarations: [ ],
  exports: [
    FontAwesomeModule
  ]
})

export class IconsModule {

    constructor () {
        // Base / Solid Icons
        // Add icons to `library` Object to make them accessible
        // Sanity Check:: Icons are listed in alphabetical order
        library.add(
          faAddressCard,
          faAngleDoubleDown,
          faAngleDoubleUp,
          faAngleDown,
          faAt,
          faAward,
          faBook,
          faBookmark,
          faBox,
          faBoxes,
          faBoxOpen,
          faBrain,
          faBriefcase,
          faBroom,
          faBug,
          faChartBar,
          faChartLine,
          faChartPie,
          faChess,
          faChevronUp,
          faChevronRight,
          faChevronDown,
          faChevronLeft,
          faCircle,
          faClock,
          faCloudDownloadAlt,
          faCode,
          faCodeBranch,
          faCog,
          faCogs,
          faComment,
          faCommentAlt,
          faCommentDots,
          faCookie,
          faCookieBite,
          faCrown,
          faCubes,
          faDatabase,
          faDesktop,
          faDiceD20,
          faDna,
          faDownload,
          faDumpster,
          faEnvelope,
          faEllipsisV,
          faExclamationTriangle,
          faFile,
          faFileAlt,
          faFileArchive,
          faFileCode,
          faFileDownload,
          faFileExcel,
          faFileExport,
          faFileImage,
          faFileImport,
          faFilePdf,
          faFileWord,
          faFireAlt,
          faFingerprint,
          faFlask,
          faGraduationCap,
          faHome,
          faIdBadge,
          faIdCard,
          faInfo,
          faInfoCircle,
          faLaptopCode,
          faLayerGroup,
          faLightbulb,
          faLink,
          faMapMarked,
          faMapMarkedAlt,
          faMapMarkerAlt,
          faMapPin,
          faMeteor,
          faMicrochip,
          faParachuteBox,
          faPencilRuler,
          faPlus,
          faPlusCircle,
          faPoo,
          faQuoteLeft,
          faQuoteRight,
          faRobot,
          faServer,
          faShareAltSquare,
          faSquare,
          faSquareFull,
          faSwatchbook,
          faSyncAlt,
          faTachometerAlt,
          faTasks,
          faTerminal,
          faTools,
          faToolbox,
          faTrafficLight,
          faUser,
          faUserAstronaut,

          // Brand Icons /////////////////////////
          faAngular,
          faApple,
          faAws,
          faChrome,
          faCss3,
          faCss3Alt,
          faDocker,
          faEmber,
          faExpeditedssl,
          faFacebook,
          faFacebookSquare,
          faFirefox,
          faGithubSquare,
          faGithubAlt,
          faGitSquare,
          faGooglePlus,
          faGooglePlusSquare,
          faGrunt,
          faGulp,
          faHtml5,
          faInstagram,
          faJsSquare,
          faLinkedin,
          faLinkedinIn,
          faNode,
          faNodeJs,
          faNpm,
          faReact,
          faSass,
          faStackExchange,
          faStackOverflow,
          faTwitter,
          faTwitterSquare,
          faVuejs,
          faWindows
        );
    }
}
